var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"p-2 px-5 my-1 bg-white rounded-lg"},[_c('ul',{staticClass:"flex"},_vm._l((_vm.tabs),function(item,key){return _c('li',{key:key,staticClass:"mr-2"},[_c('button',{staticClass:"px-3 py-2 text-lg border rounded-lg",class:_vm.currentTab === key
                            ? 'bg-red-500 text-white border-red-500'
                            : 'border-red-500 text-red-500',on:{"click":function($event){$event.preventDefault();_vm.currentTab = key}}},[_vm._v(" "+_vm._s(item)+" ")])])}),0),_c('div',{staticClass:"flex items-center"},[_c('h1',{staticClass:"flex-grow my-5 text-xl"},[_vm._v(" 聊天室"+_vm._s(_vm.tabs[_vm.currentTab])+" ")]),_c('button',{directives:[{name:"permission",rawName:"v-permission",value:(['create']),expression:"['create']"}],staticClass:"blue-btn",on:{"click":function($event){$event.preventDefault();return _vm.addColumn.apply(null, arguments)}}},[_vm._v(" 新增 ")])]),_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('table',{staticClass:"myTable"},[_c('thead',[_c('tr',[_c('th',[_vm._v("編號")]),_c('th',[_vm._v("內容")])])]),(Object.keys(_vm.form).length > 0)?_c('tbody',_vm._l((_vm.form[_vm.currentTab]),function(data,key){return _c('tr',{key:key},[_c('td',{staticClass:"w-[100px] text-center cursor-pointer",on:{"click":function($event){return _vm.dropColmun(key)}}},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"mr-2 whitespace-nowrap"},[_vm._v(" "+_vm._s(key)+" ")]),_c('i',{staticClass:"text-2xl text-orange-600 icofont-ui-delete"})])]),_c('td',[_c('el-form-item',{staticClass:"mb-0",attrs:{"prop":`${key}`}},[_c('label',{staticClass:"mb-0 leading-none"},[_c('el-input',{model:{value:(
                                            _vm.form[_vm.currentTab][key][
                                                'keywords'
                                            ]
                                        ),callback:function ($$v) {_vm.$set(_vm.form[_vm.currentTab][key], 
                                                'keywords'
                                            , $$v)},expression:"\n                                            form[currentTab][key][\n                                                'keywords'\n                                            ]\n                                        "}})],1)])],1)])}),0):_vm._e()])]),_c('div',{staticClass:"flex justify-center my-5"},[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['create', 'update']),expression:"['create', 'update']"}],staticClass:"mb-10",attrs:{"type":"primary","native-type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit('form')}}},[_vm._v(" 更新 ")])],1)],1),_c('div',{staticClass:"p-2 px-5 my-1 bg-white rounded-lg"},[_c('h1',{staticClass:"my-5 text-xl text-center"},[_vm._v("設定關鍵字警告回應內容")]),_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('label',[_c('el-form-item',{attrs:{"prop":"keyword_warning_content"}},[_c('el-input',{model:{value:(_vm.form.keyword_warning_content),callback:function ($$v) {_vm.$set(_vm.form, "keyword_warning_content", $$v)},expression:"form.keyword_warning_content"}})],1)],1)]),_c('div',{staticClass:"flex justify-center my-5"},[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['create', 'update']),expression:"['create', 'update']"}],staticClass:"mb-10",attrs:{"type":"primary","native-type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit('form')}}},[_vm._v(" 更新 ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }