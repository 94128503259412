<template>
    <div>
        <div class="p-2 px-5 my-1 bg-white rounded-lg">
            <ul class="flex">
                <li v-for="(item, key) in tabs" :key="key" class="mr-2">
                    <button
                        :class="
                            currentTab === key
                                ? 'bg-red-500 text-white border-red-500'
                                : 'border-red-500 text-red-500'
                        "
                        class="px-3 py-2 text-lg border rounded-lg"
                        @click.prevent="currentTab = key"
                    >
                        {{ item }}
                    </button>
                </li>
            </ul>
            <div class="flex items-center">
                <h1 class="flex-grow my-5 text-xl">
                    聊天室{{ tabs[currentTab] }}
                </h1>
                <button
                    v-permission="['create']"
                    class="blue-btn"
                    @click.prevent="addColumn"
                >
                    新增
                </button>
            </div>
            <el-form ref="form" :model="form" :rules="rules">
                <table class="myTable">
                    <thead>
                        <tr>
                            <th>編號</th>
                            <th>內容</th>
                        </tr>
                    </thead>
                    <tbody v-if="Object.keys(form).length > 0">
                        <tr v-for="(data, key) in form[currentTab]" :key="key">
                            <td
                                class="w-[100px] text-center cursor-pointer"
                                @click="dropColmun(key)"
                            >
                                <div class="flex items-center">
                                    <div class="mr-2 whitespace-nowrap">
                                        {{ key }}
                                    </div>
                                    <i
                                        class="text-2xl text-orange-600 icofont-ui-delete"
                                    ></i>
                                </div>
                            </td>
                            <td>
                                <el-form-item class="mb-0" :prop="`${key}`">
                                    <label class="mb-0 leading-none">
                                        <el-input
                                            v-model="
                                                form[currentTab][key][
                                                    'keywords'
                                                ]
                                            "
                                        ></el-input>
                                    </label>
                                </el-form-item>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </el-form>
            <div class="flex justify-center my-5">
                <el-button
                    v-permission="['create', 'update']"
                    class="mb-10"
                    type="primary"
                    native-type="submit"
                    @click.prevent="onSubmit('form')"
                >
                    更新
                </el-button>
            </div>
        </div>
        <div class="p-2 px-5 my-1 bg-white rounded-lg">
            <h1 class="my-5 text-xl text-center">設定關鍵字警告回應內容</h1>
            <el-form ref="form" :model="form" :rules="rules">
                <label>
                    <el-form-item prop="keyword_warning_content">
                        <el-input
                            v-model="form.keyword_warning_content"
                        ></el-input>
                    </el-form-item>
                </label>
            </el-form>
            <div class="flex justify-center my-5">
                <el-button
                    v-permission="['create', 'update']"
                    class="mb-10"
                    type="primary"
                    native-type="submit"
                    @click.prevent="onSubmit('form')"
                >
                    更新
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "firebase/app";
import { firebaseConnectRef } from "@/plugins/firebase";

export default {
    name: "socialMediaKeywordsReplace",
    data() {
        return {
            form: {
                line_replace_keywords: {},
                instagram_replace_keywords: {},
                facebook_replace_keywords: {},
                wechat_replace_keywords: {},
                telegram_replace_keywords: {},
                other_replace_keywords: {},
                keyword_warning_content: "",
            },
            rules: {
                line_replace_keywords: {},
                instagram_replace_keywords: {},
                facebook_replace_keywords: {},
                wechat_replace_keywords: {},
                telegram_replace_keywords: {},
                other_replace_keywords: {},
                keyword_warning_content: [
                    {
                        required: true,
                        message: "關鍵字警告回應內容",
                        trigger: "blur",
                    },
                ],
            },
            tabs: {
                line_replace_keywords: "LINE 關鍵字過濾",
                instagram_replace_keywords: "IG 關鍵字過濾",
                facebook_replace_keywords: "FB 關鍵字過濾",
                wechat_replace_keywords: "微信 關鍵字過濾",
                telegram_replace_keywords: "Telegram 關鍵字過濾",
                other_replace_keywords: "其它 關鍵字過濾",
            },
            // 選中 tab
            currentTab: null,
        };
    },
    methods: {
        // 新增欄位
        async addColumn() {
            // 預設表單資料
            function setDefaultData() {
                this.keywords = "";
            }
            const defaultData = new setDefaultData();
            try {
                const newKey = await firebase
                    .database()
                    .ref(`social_media_replace_keywords/${this.currentTab}`)
                    .push().key;
                this.$set(this.form[this.currentTab], newKey, defaultData);
                this.$set(this.rules[this.currentTab], `${newKey}`, [
                    {
                        required: true,
                        message: "關鍵字為必填",
                        trigger: "blur",
                    },
                ]);
                console.log(this.form);
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "新增過濾關鍵字失敗",
                });
            }
        },
        /**
         * 刪除欄位
         * @param { type String(字串) } key 刪除key
         */
        async dropColmun(key) {
            try {
                await this.$confirm(
                    `刪除此過濾關鍵字${
                        this.form[this.currentTab][key]["keywords"]
                    }?`,
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                );
                this.$delete(this.form[this.currentTab], key);
                firebase
                    .database()
                    .ref(
                        `social_media_replace_keywords/${this.currentTab}/${key}`
                    )
                    .remove();
                this.$message({
                    type: "success",
                    message: "刪除成功",
                });
            } catch (err) {
                console.log(err);
            }
        },
        // 更新
        async update() {
            try {
                await firebaseConnectRef("social_media_replace_keywords").set({
                    ...this.form,
                    updatedAt: firebase.database.ServerValue.TIMESTAMP,
                });
                this.$message({
                    type: "success",
                    message: "更新成功",
                });
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "更新失敗",
                });
            }
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                await this.update();
                await this.getList();
            } catch (err) {
                console.log(err, this.$refs[formName]);
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        // 取得列表
        async getList() {
            try {
                const data = await firebase
                    .database()
                    .ref("social_media_replace_keywords")
                    .get();
                // 判斷是否有訊息
                if (data.exists()) {
                    this.setDefault(data.val());
                }
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "取得數據失敗",
                });
            }
        },
        // 設定表單資料
        setDefault(datas) {
            if (Object.keys(datas).length > 0) {
                Object.keys(datas).forEach((objKey) => {
                    if (objKey !== "updatedAt") {
                        if (objKey === "keyword_warning_content") {
                            this.$set(this.form, objKey, datas[objKey]);
                        } else {
                            this.$set(this.form, objKey, {});
                            Object.keys(datas[objKey]).forEach((objKey2) => {
                                this.$set(this.rules[objKey], `${objKey2}`, [
                                    {
                                        required: true,
                                        message: "關鍵字為必填",
                                        trigger: "blur",
                                    },
                                ]);

                                this.$set(this.form[objKey], objKey2, {});
                                this.$set(
                                    this.form[objKey][objKey2],
                                    "keywords",
                                    datas[objKey][objKey2]["keywords"]
                                );
                            });
                        }
                    }
                });
            } else {
                Object.keys(this.tabs).forEach((objKey) => {
                    this.$set(this.form, objKey, {});
                });
            }
        },
    },
    created() {
        this.currentTab = "line_replace_keywords";
        this.getList();
    },
};
</script>
